import PeopleSection from "./peoplesection";
import BookSection from "./booksection";
import Quote from "./quote";
import { get_random } from "./utils";
import { Link } from "gatsby";
import SEO from "./seo";
function Top({ top_books, top_type, people }) {
  // let { subset } = useParams();

  let subset = "todos";

  const route_subset = {
    todos: "Top 100 livros mais recomendados de todos os tempos",
    ficcao: "Top 100 livros de ficção mais recomendados",
    "nao ficção": "Top 100 livros de não-ficção mais recomendados",
  };
  let readable_h1 = route_subset[top_type];

  return (
    <div className="gap-y-5 px-10 md:px-24 xl:px-60">
      <SEO
        title={readable_h1}
        description={"Não sabe o que ler em 2022? Veja o " + readable_h1}
      />
      <div className="grid lg:grid-cols-2 grid-cols-1 text-center lg:text-left items-center">
        <div className="text-2xl lg:text-4xl font-light text-blue-800 ">
          <h1>{readable_h1}</h1>
        </div>
        <div className="text-xl font-light text-blue-800 my-8 lg:my-16 place-items-center grid">
          <div className="w-3/4">
            <h2>
              Aqui voce vai descobrir o que as pessoas mais bem sucedidas do
              mundo estão lendo
            </h2>
          </div>
        </div>
      </div>
      <div></div>
      <div className="grid">
        <div className="flex flex-row text-xl md:text-2xl text-blue-800 gap-x-3 mt-10">
          <Link to="/top/todos" activeClassName="text-blue-900 font-bold">
            Todos
          </Link>
          <Link to="/top/ficcao" activeClassName="text-blue-900 font-bold">
            Ficção
          </Link>
          <Link to="/top/nao-ficcao" activeClassName="text-blue-900 font-bold">
            Não-Ficção
          </Link>
        </div>
      </div>

      <BookSection books={top_books} cta="" />
      <PeopleSection people={people} cta="Pessoas em destaque" />
      <Quote />
    </div>
  );
}

export default Top;

import Top from "../components/top";
import Layout from "../components/layout";
import { normalize_graphql_data } from "../components/utils";
const PeopleRootPage = (props) => {
  let top_books = props.pageContext.top_data;
  let top_type = props.pageContext.top_type;
  let other_people = normalize_graphql_data(props.pageContext.other_people);

  return (
    <Layout pageTitle="Home Page">
      <Top top_books={top_books} top_type={top_type} people={other_people} />
    </Layout>
  );
};

export default PeopleRootPage;
